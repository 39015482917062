import React from 'react';
import { Link } from 'gatsby';
import Layout from 'src/components/layout/main';
import SEO from 'src/components/seo';
import Container from 'src/components/base/container';
import Section from 'src/components/base/section';
import Button from 'src/components/base/button';
import Typography from 'src/components/base/typography';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Section padding="4rem">
        <Typography as="h1" family="display" size="1" weight="medium">
          NOT FOUND
        </Typography>
        <Typography as="p">
          <span>You just hit a route that doesn&#39;t exist... the sadness. </span>
        </Typography>
        <Link href="/">
          <Button radius="small">Go home</Button>
        </Link>
      </Section>
    </Container>
  </Layout>
)

export default NotFoundPage;
